import useBooleanState from '@fingo/lib/hooks/useBooleanState';
import Close from '@mui/icons-material/Close';
import Menu from '@mui/icons-material/Menu';
import Remove from '@mui/icons-material/Remove';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import styled from '@mui/styles/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const useStyles = makeStyles(() => ({
  logo: {
    width: 90,
  },
  activeStyle: {
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  },
}));

const LoggedOutMobileNavItems = ({ withRegister }) => {
  const classes = useStyles();
  const [drawerOpen, toggleDrawer] = useBooleanState(false);

  return (
    <>
      <IconButton onClick={toggleDrawer}>
        <Menu color="primary" />
      </IconButton>
      <Drawer
        variant="temporary"
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          '& .MuiPaper-root': {
            minWidth: 280,
            bgcolor: 'text.primary',
            pb: 4,
          },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawer} id="mobile-nav-close">
            <Close sx={{ color: 'common.white' }} />
          </IconButton>
        </DrawerHeader>
        <List>
          <ListItemButton
            component={NavLink}
            to="/login"
            activeClassName={classes.activeStyle}
            id="mobile-nav-login"
          >
            <ListItemIcon sx={{ minWidth: 0, mr: 3 }}>
              <Remove color="common.white" sx={{ transform: 'scale(3, 1)' }} />
            </ListItemIcon>
            <ListItemText
              primary="Ingresar"
              primaryTypographyProps={{
                color: 'white',
                variant: 'h6',
              }}
            />
          </ListItemButton>
          {withRegister && (
            <ListItemButton
              component={NavLink}
              to="/login?loginType=signup"
              activeClassName={classes.activeStyle}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: 3 }}>
                <Remove color="common.white" sx={{ transform: 'scale(3, 1)' }} />
              </ListItemIcon>
              <ListItemText
                primary="Registrarse"
                primaryTypographyProps={{
                  color: 'white',
                  variant: 'h6',
                }}
              />
            </ListItemButton>
          )}
        </List>
      </Drawer>
    </>
  );
};

LoggedOutMobileNavItems.propTypes = { withRegister: PropTypes.bool };

LoggedOutMobileNavItems.defaultProps = { withRegister: true };

export default LoggedOutMobileNavItems;
