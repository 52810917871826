const MuiOutlinedInput = {
  styleOverrides: {
    root: ({ theme }) => ({
      minHeight: 30, // 48 in leia
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.gray.A400,
        borderRadius: 30, // 8 in leia
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.gray.A600}`,
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.primary.A500}`,
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.error.A600}`,
      },
      '&.Mui-error:hover .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.palette.error.A600}`,
      },
      '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.gray.A200,
      },
      '&.Mui-disabled': {
        backgroundColor: theme.palette.gray.A100,
        pointerEvents: 'none',
        borderRadius: 30, // 8 in leia
        border: `1px solid ${theme.palette.gray.A200}`,
      },
    }),
    input: ({ theme }) => ({
      padding: '2px 14px',
      color: theme.palette.gray.A800,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '140%',
      fontFamily: 'Plus Jakarta Sans',
      '&.Mui-disabled': {
        color: theme.palette.text.disabled,
      },
    }),
  },
};

export default MuiOutlinedInput;
