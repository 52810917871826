import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import HideOnScroll from '../scrolls/HideOnScroll';
import useEnvironment from '../../hooks/useEnvironment';

const styles = {
  navbar: {
    minHeight: 55,
    justifyContent: 'center',
    bgcolor: 'common.white',
  },
  gradientAnimation: {
    '@keyframes rainbow-background': {
      '0%': { backgroundPosition: '0 0' },
      '100%': { backgroundPosition: '200% 0' },
    },
  },
  localhostGradient: {
    background: `linear-gradient(
      to right,
      #6B2FBC,
      #8438EA,
      #A034DC,
      #C536B5,
      #E23A92,
      #FF3E79,
      #6B2FBC
    )`,
    backgroundSize: '200% 100%',
    animation: 'rainbow-background 8s linear infinite',
  },
};

const FingoNavbar = ({ children }) => {
  const { isLocalhost, name, isStaging } = useEnvironment();

  return (
    <HideOnScroll>
      <AppBar
        elevation={0}
        position="sticky"
        sx={{
          ...styles.navbar,
          ...styles.gradientAnimation,
          ...((isLocalhost || isStaging) && styles.localhostGradient),
        }}
      >
        <Toolbar>
          {(isLocalhost || isStaging) && (
            <Typography style={{ marginRight: 16, color: 'white', fontWeight: 'bold' }}>
              {name?.toUpperCase()}
            </Typography>
          )}
          {children}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

FingoNavbar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FingoNavbar;
