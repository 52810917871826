import PropTypes from 'prop-types';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import buildCdnUrl from '../../helpers/cdn';
import useResponsiveImage from '../../hooks/useResponsiveImage';

const LazyImage = ({
  imagePath,
  alt,
  width,
  height,
  format = 'webp',
  quality = 100,
}) => {
  const { width: resolvedWidth, height: resolvedHeight } = useResponsiveImage(
    width,
    height,
  );

  const imageUrl = buildCdnUrl(imagePath, {
    width: resolvedWidth,
    height: resolvedHeight,
    format,
    quality,
  });

  return (
    <LazyLoadImage
      alt={alt}
      src={imageUrl}
      style={{
        width: resolvedWidth || width,
        height: resolvedHeight || height,
      }}
    />
  );
};

LazyImage.propTypes = {
  imagePath: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  format: PropTypes.string,
  quality: PropTypes.number,
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      mobile: PropTypes.number,
      tablet: PropTypes.number,
      desktop: PropTypes.number,
      large: PropTypes.number,
    }),
  ]).isRequired,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      mobile: PropTypes.number,
      tablet: PropTypes.number,
      desktop: PropTypes.number,
      large: PropTypes.number,
    }),
  ]).isRequired,
};

LazyImage.defaultProps = {
  format: 'webp',
  quality: 100,
};

export default LazyImage;
