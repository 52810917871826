import { logEvent } from './events';

const useAnalytics = () => {
  const checkConversion = (label) => {
    if (label === 'register-button') {
      logEvent('event', 'conversion', 'register-button');
    }
  };

  const trackEvent = (category, action, label) => {
    logEvent(category, action, label);
    checkConversion(label);
    logEvent(
      category,
      action,
      label,
    );
  };

  return { trackEvent };
};

export default useAnalytics;
