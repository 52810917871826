const MuiTextField = {
  defaultProps: {
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  },
  styleOverrides: {
    root: {
      '& .MuiFormHelperText-root': {
        minHeight: 20, // Ensures space for helper text
        fontSize: 10,
        visibility: 'hidden', // Hides the helper text placeholder when no text is present
      },
      '& .MuiFormHelperText-root.Mui-error': {
        visibility: 'visible', // Ensures error messages are displayed
      },
      '& .MuiFormHelperText-root:not(:empty)': {
        visibility: 'visible', // Ensures any helper text content is displayed
      },
    },
  },
};

export default MuiTextField;
