import { split } from '@apollo/client';
import { ApolloClient } from '@apollo/client/core/ApolloClient';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities/graphql/getFromAST';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { SubscriptionClient } from 'subscriptions-transport-ws/dist/client';
import { getValidToken } from '../helpers/custom-fetch';
import { cacheCreator } from './cache';
import { tokenVar } from './reactive-variables/auth';

const wsUri = process.env.REACT_APP_WEBSOCKET_SERVER || 'ws://localhost/ws/graphql/';
const uri = process.env.REACT_APP_GRAPHQL_SERVER_URI;

const uploadLink = createUploadLink({
  uri,
});
const wsLink = new WebSocketLink(
  new SubscriptionClient(
    wsUri,
    {
      lazy: true,
      options: {
        reconnect: true,
      },
      inactivityTimeout: 1000,
      connectionParams: () => ({
        token: tokenVar() || '',
      }),
    },
  ),
);

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await getValidToken(uri);
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `JWT ${accessToken}` : '',
      'Accept-Language': 'es',
    },
  };
});
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(uploadLink),
);

const client = new ApolloClient({
  cache: cacheCreator(),
  link: splitLink,
  connectToDevTools: process.env.NODE_ENV !== 'production',
  resolvers: {},
});

export default client;
