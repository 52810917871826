const MuiFormHelperText = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.gray.A500,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '140%',
      fontFamily: 'Plus Jakarta Sans',
      opacity: 1,
      '&.Mui-error': {
        color: theme.palette.error.A600,
      },
      '&.Mui-disabled': {
        color: theme.palette.gray.A400,
      },
    }),
  },
};

export default MuiFormHelperText;
