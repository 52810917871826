import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import useDynamicFingoLogo from '../../hooks/useDynamicFingoLogo';

const FingoCircularProgressLoader = () => {
  const fingoLogo = useDynamicFingoLogo();
  return (
    <Stack alignItems="center">
      <svg>
        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
          <stop offset="20%" stopColor="#E62E66" />
          <stop offset="90%" stopColor="#F7F7F7" />
        </linearGradient>
      </svg>
      <CircularProgress
        size={60}
        sx={{ circle: { stroke: 'url(#linearColors)' } }}
      />
      <Typography pt={2} variant="body2" fontWeight="500">LA LIQUIDEZ QUE NECESITAS HOY</Typography>
      <Icon sx={{ width: 150, height: 'auto' }}>
        <img src={fingoLogo} style={{ height: '100%', width: '100%' }} alt="fingo-logo" />
      </Icon>
    </Stack>
  );
};

export default FingoCircularProgressLoader;
