const MuiSelect = {
  defaultProps: {
    inputProps: {
      'data-hj-allow': '',
      borderRadius: 2,
      minHeight: 48,
    },
  },
  styleOverrides: {
    root: {
      '&.MuiOutlinedInput-root': {
        '& fieldset': {
          borderRadius: 2,
        },
      },
    },
    select: {
      fontSize: '12px',
      padding: '12px',
    },
    icon: {
      fontSize: '20px',
    },
  },
};

export default MuiSelect;
