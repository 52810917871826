import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import useIsMobile from '../../hooks/useIsMobile';
import useReactTrackingLayer from '../../hooks/useReactTrackingLayer';
import useSelectedCompany from '../../hooks/useSelectedCompany';
import useUniqueHash from '../../hooks/useUniqueHash';
import ButtonTrackerApp from './ButtonTrackerApp';
import { initGA } from './google/initialize';
import useAnalytics from './google/useAnalytics';

const TrackerProvider = ({ children, frontendName, googleAnalyticsTag = null }) => {
  const dispatch = useReactTrackingLayer(frontendName);
  const history = useHistory();
  const location = useLocation();
  const selectedCompany = useSelectedCompany();
  const isMobile = useIsMobile();
  const uniqueHash = useUniqueHash();
  const { trackEvent: trackGoogleEvent } = useAnalytics();
  const searchParams = new URLSearchParams(location.search);
  const session = searchParams.get('session') || uniqueHash;
  // Tracking code for changing pages
  useEffect(() => {
    if (googleAnalyticsTag) {
      initGA(googleAnalyticsTag);
    }
  }, []);

  const domain = window.location.hostname;
  // Tracking code for changing pages
  const { Track, trackEvent } = useTracking(
    {
      route: location.pathname,
      domain,
      searchParams: location.search,
      company: selectedCompany,
      isMobile,
      session,
    },
    { dispatch },
  );
  useEffect(() => {
    trackEvent({ action: 'LOADED' });
    const unlisten = history.listen((newLocation, b) => {
      if (b === 'PUSH') {
        trackEvent({
          route: newLocation.pathname,
          searchParams: newLocation.search,
          action: 'LOADED' });
      }
      trackGoogleEvent('Page', 'Load', location.pathname);
    });
    return () => unlisten();
  }, []);
  return (
    <Track>
      <ButtonTrackerApp>
        {children}
      </ButtonTrackerApp>
    </Track>
  );
};

TrackerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  frontendName: PropTypes.string.isRequired,
  googleAnalyticsTag: PropTypes.string,
};

TrackerProvider.defaultProps = {
  googleAnalyticsTag: null,
};

export default TrackerProvider;
