const MuiInputLabel = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.gray.A700,
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '140%',
      padding: '0 4px 0 4px',
      fontFamily: 'Plus Jakarta Sans',
      '&.Mui-focused': {
        color: theme.palette.primary.A500,
        '& .MuiInputLabel-asterisk': {
          color: theme.palette.primary.A500,
        },
      },
      '&.Mui-error': {
        color: theme.palette.error.A600,
        '& .MuiInputLabel-asterisk': {
          color: theme.palette.error.A600,
        },
      },
      '&.Mui-disabled': {
        color: theme.palette.gray.A400,
        backgroundColor: 'white',
        '& .MuiInputLabel-asterisk': {
          color: theme.palette.gray.A400,
        },
      },
    }),
    asterisk: ({ theme }) => ({
      color: theme.palette.error.A600,
      fontSize: 14,
    }),
  },
};

export default MuiInputLabel;
