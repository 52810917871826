import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChileFlagIcon from '../assets/icons/flags/chile';
import MexicoFlagIcon from '../assets/icons/flags/mexico';
import SiiLogo from '../assets/icons/logos/sii';
import SatLogo from '../assets/icons/logos/sat';

const spanishTranslations = {
  abandoned_shopping_cart_mail: 'carrito abandonado',
  invoice_reception_mail: 'facturas recibidas',
  invoice_rejection_mail: 'facturas rechazadas',
  approved_offers: 'facturas aprobadas',
  to_expire_offers: 'facturas por expirar',
  preoffer: 'pre oferta',
  never_evaluated: 'promociones de ofertas de tasa a usuarios nuevos',
};

i18n.use(initReactI18next).init({
  resources: {
    Chile: {
      translation: {
        'National Tax Service': 'Servicio de Impuestos Internos',
        'National Tax Service credentials': 'Servicio de Impuestos Internos',
        'National Tax Service acronym': 'SII',
        'National Tax Service logo': SiiLogo,
        'National identifier': 'RUT',
        'Credential Password': 'Contraseña',
        'Phone prefix': '56',
        'Phone Number Length': 9,
        'account number': 'Número de la cuenta',
        'National tax service credential provider': 'Sii',
        'offer action': 'Ceder y girar',
        'default currency': 'CLP',
        'Price diference': 'Diferencia de precio',
        Direction: 'Av. Apoquindo 4660, Piso 8. Las Condes',
        retention: 'Monto retenido',
        dte: 'Tipo',
        flag: ChileFlagIcon,
        'landing-path': 'https://fingo.cl',
        'Estados Financieros': 'Estados Financieros',
        ...spanishTranslations,
      },
    },
    Mexico: {
      translation: {
        'National Tax Service': 'Servicio de Administración Tributaria',
        'National Tax Service credentials': 'SAT (CIEC)',
        'National Tax Service acronym': 'SAT',
        'National Tax Service logo': SatLogo,
        'National identifier': 'RFC',
        'Credential Password': 'CIEC',
        'default currency': 'MXN',
        'Phone prefix': '52',
        'Phone Number Length': 10,
        'National tax service credential provider': 'Sat',
        'account number': 'Cuenta CLABE',
        'offer action': 'Ceder derechos de cobro y operar',
        'Price diference': 'Interés',
        Direction: 'Varsovia No. 36 Int Piso 4, Int. 408, Colonia Juárez C.p. 6600 Alcaldía: Cuauhtémoc Entidad Federativa: Ciudad De México',
        retention: 'Monto retenido por aforo',
        dte: 'Efecto',
        flag: MexicoFlagIcon,
        'landing-path': 'https://www.fingo.com.mx/',
        'Estados Financieros': 'Estados de resultados',
        ...spanishTranslations,
      },
    },
  },
  lng: 'Chile',
  fallbackLng: 'Chile',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
