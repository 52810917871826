import { useMemo } from 'react';

const useEnvironment = () => {
  const environment = useMemo(() => {
    const hostname = typeof window !== 'undefined' ? window.location.hostname : '';
    const isLocalhost = hostname === 'localhost';
    const isStaging = hostname.includes('staging');
    return {
      isLocalhost,
      isStaging: !isLocalhost && isStaging,
      isProd: !isLocalhost && !isStaging,
      name: (() => {
        if (isLocalhost) return 'local';
        if (isStaging) return 'staging';
        return 'production';
      })(),
    };
  }, []);

  return environment;
};

export default useEnvironment;
