const palette = {
  primary: {
    A100: '#FFDBE6',
    A200: '#FCC6D6',
    A300: '#F7A7C0',
    A400: '#F389A9',
    A500: '#EF6B93',
    A600: '#EB5180',
    main: '#E62E66',
    A700: '#E62E66',
    A800: '#C52163',
  },
  secondary: {
    A100: '#FDE0FA',
    A200: '#FEE0FF',
    A300: '#F8C9FA',
    A400: '#EB96F5',
    A500: '#CB5FE3',
    A600: '#A436C7',
    main: '#7205A3',
    A800: '#58038C',
  },
  gray: {
    A100: '#F2F4F7',
    A200: '#EAECF0',
    A300: '#D0D5DD',
    A400: '#98A2B3',
    A500: '#667085',
    main: '#667085',
    A600: '#475467',
    A700: '#344054',
    A800: '#101828',
  },
  success: {
    A100: '#EEFFED',
    A200: '#D5FBD3',
    A300: '#A9F8AF',
    A400: '#7BEC8E',
    main: '#58D97A',
    A600: '#28C160',
    A700: '#1DA55C',
    A800: '#148A57',
  },
  error: {
    A100: '#FFF1E8',
    A200: '#FEE9DC',
    A300: '#FECDBA',
    A400: '#FDAB97',
    main: '#FC8B7D',
    A600: '#FB5752',
    A700: '#D73B44',
    A800: '#B4293C',
  },
  info: {
    A100: '#DDEFFF',
    A200: '#C9E3FB',
    A300: '#95C7F7',
    A400: '#5E9FE9',
    main: '#3579D3',
    A600: '#0148B7',
    A700: '#00379D',
    A800: '#002983',
  },
  warning: {
    A100: '#FFFAEB',
    A200: '#FFF5CE',
    A300: '#FFE79C',
    A400: '#FFD66C',
    main: '#FFC647',
    A600: '#FFAB0A',
    A700: '#DB8B07',
    A800: '#B76D05',
  },
  text: {
    main: '#323232',
    // We need to get rid of this color, but in the meantime
    // we need to keep it for the alert messages :(
  },
};

export default palette;
